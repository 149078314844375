import logoDark from "@/images/logo.png";
export const Logo = {
  dark: logoDark
};

export const NavLinksData = [
  {
    name: "Home",
    url: "/"
  },
  {
    name: "About",
    url: "/about"
  },
  {
    name: "Products",
    url: "/products",
    subItems: [
      {
        name: "Sign Blanks",
        url: "/signblanks"
      },
      {
        name: "Signposts",
        url: "/signposts"
      },
      {
        name: "Sign Stands",
        url: "/signstands"
      }
    ]
  },
  {
    name: "Contact",
    url: "/contact"
  }
];

import sliderOne1 from "@/images/banner-1-1.jpg";
import sliderOne2 from "@/images/banner-1-2.jpg";

export const SliderOneData = [
  {
    image: sliderOne1,
    title: "SERVING THE SIGN INDUSTRY SINCE 1998",
    subText: "Newby Enterprises is the Prairie Distributor for Multipanels Alupanel ",
    button: {
      label: "Get a Quote",
      url: "/quote"
    }
  },
];

import sliderTwo1 from "@/images/banner-featured-1-1.png";

export const SliderTwoData = {
  image: sliderTwo1,
  posts: [
    {
      title: "Quality \n Printing \n Service",
      subTitle: "We believe in the power of great design",
      button: {
        label: "Our Pricing",
        url: "/pricing"
      }
    },
    {
      title: "Quality \n Printing \n Service",
      subTitle: "We believe in the power of great design",
      button: {
        label: "Our Pricing",
        url: "/pricing"
      }
    }
  ]
};

import video1 from "@/images/video-box-1-1.jpg";
export const VideoData = {
  image: video1,
  ID: "mUhcFs6v-Xw",
  caption: "Printfinity Makes \n Every Card \n Unique"
};

export const CallToActionData = {
  sectionContent: {
    subText: "Business stationery printing",
    title: "Even More Good \n Stuff",
    textAlignment: "left"
  },
  lists: [
    "Professional designs with added fizz",
    "Create an army of business stationery",
    "Take your attention to detail up a level",
    "Totally safe for laser printers"
  ],
  button: {
    label: "Learn More",
    url: "/about"
  }
};

import callToActionTwo1 from "@/images/free-sample-1-2.jpg";

export const CallToActionTwoData = {
  sectionContent: {
    subText: "New Product",
    title: "MultiPanel UK Provider",
    textAlignment: "left"
  },
  content:
    "From eye-catching signage and innovative POS displays, to highly effective way finding applications, these aluminium" +
      " composite panels are the all-round sign and display solution. We are the exclusive Western Canadian provider. ",
  button: {
    label: "Learn More",
    url: "https://www.multipaneluk.co.uk/"
  },
  image: {
    name: callToActionTwo1,
    caption: "Exclusive Western Canadian Provider"
  }
};

export const CallToActionThreeData = {
  title: "Request a \n  Custom Quote.",
  subText: "Let's get started",
  button: {
    label: "Get a Quote",
    url: "/quote"
  }
};

import aboutThree1 from "@/images/service-bg-1-1.jpg";

export const AboutThreeData = {
  backgroundImage: aboutThree1,
  sectionContent: {
    subText: "Checkout our services",
    title: "Explore Printing \n Products",
    textAlignment: "left"
  },
  content:
    "We’re an online print and design company that is passionate about great design and the difference it can make to our customers and the world. \n \n We want to set a new standard for print, with remarkable new products that bring great design and uncompromising, high standards to the web.",
  button: {
    label: "Learn More",
    url: "/about"
  },
  phone: "000 8888 999",
  posts: [
    {
      title: "T-Shirt \nPrinting",
      icon: "printify-icon-t-shirt-with-square",
      url: "/service-details"
    },
    {
      title: "Flyer \nPrinting",
      icon: "printify-icon-brochure-folded",
      url: "/service-details"
    },
    {
      title: "Poster \n Printing",
      icon: "printify-icon-continuous-feed-paper",
      url: "/service-details"
    },
    {
      title: "Sticker  \n Printing",
      icon: "printify-icon-circular-sticker",
      url: "/service-details"
    }
  ]
};

import aboutTwo1 from "@/images/welcome-1-1.png";

export const AboutTwoData = {
  sectionContent: {
    subText: "Trusted Sign Blank Providers",
    title: "Who We Are",
    textAlignment: "left"
  },
  content:
    "We are a service-first, satisfaction-focused company that manufactures sign blanks for print shops in the sign" +
      " industry. We are proud to provide our valued customers with blank canvases to bring their visions to life. " +
      "From traffic and construction or oil & gas regulatory signs to custom projects and creative pursuits, we offer" +
      " high-quality blanks across an array of industries. ",
  url: "/about",
  image: aboutTwo1
};

export const FaqData = {
  sectionContent: {
    subText: "We've got answers",
    title: "Frequently \nAsked Questions",
    textAlignment: "left"
  },
  posts: [
    {
      title: "Do you offer printing services?",
      content:
        "We don’t print signs or sign designs. \n \n  Rather, we manufacture and sell sign blanks as the foundation of all of your sign printing needs. Head to our “products” page to learn about what we sell and the options within each product type. "
    },
    {
      title: "Do you have bulk pricing?",
      content:
        "Absolutely, if you’d like to buy in bulk, we’ll be sure that your price reflects that. All of our quotes are custom. Feel free to fill out a quote request on our “get a quote” page or call us directly to chat about your order."
    },
    {
      title: "Do you sell different sizes of blanks?",
      content:
        "We offer a range of sizes in each of our sign banks. Please see our “products” page to see which sizes we carry. If you’re looking for something that isn’t listed, feel free to get in touch."
    },
    {
      title: "Do you sell 3M reflective?",
      content:
        "Yes! \n \n Most of our products are available to be made with 3M reflective material."
    },
    {
      title: "What face colours do you offer?",
      content:
          "Most 3M Reflective colours and grades are available at Newby Ent. See each product to learn about what your options are."
    },
    {
      title: "Can I choose my sign stand or sign post colours?",
      content:
          "You cannot choose the colour of your sign stands or posts beyond what’s available. See our product specifics or get in touch to learn about your options. "
    },
    {
      title: "How quickly will my order be ready?",
      content:
          "We’re all about quick turnaround! Most orders are ready for same or next-day shipping. Production and delivery times aren’t guaranteed and are dependent on the scope of your project. We’ll be sure to give you a timeline along with your quote."
    },
    {
      title: "Can you help me decide what products are best for me? ",
      content:
          "Absolutely! We have over 20 years of experience in the industry. We will happily talk through your needs with you to recommend the best products for your situation. "
    }
  ]
};

import featureCarousel1 from "@/images/we-belive-in-1-1.jpg";
import featureCarousel2 from "@/images/we-belive-in-1-2.jpg";

export const FeatureCarouselData = [
  {
    image: featureCarousel1,
    title: "We believe in the \n power of great \n design",
    text:
      "Design helps us stand out: from the clothes we wear, to the homes we live in, to the business cards we use. Design tells a story about \n us and what we stand for.",
    url: "/services"
  },
  {
    image: featureCarousel2,
    title: "We believe in the \n power of great \n design",
    text:
      "Design helps us stand out: from the clothes we wear, to the homes we live in, to the business cards we use. Design tells a story about \n us and what we stand for.",
    url: "/services"
  }
];

import team1 from "@/images/team-1-1.png";
import team2 from "@/images/team-1-2.png";
import team3 from "@/images/team-1-3.png";

export const TeamOneData = {
  sectionContent: {
    title: "What you can expect",
    subText: "Our Promise",
    content:
      "When you work with Newby Enterprises, you can expect responsive and friendly service, speedy fulfillment," +
        " and high-quality products. Many of our signs are cut daily for next-day shipping and we offer" +
        " pre-printed 3M HI INT stop and yield signs to suit the more common sign demands. Whether you’re looking for sign blanks, sign stands," +
        " or sign posts, you will find what you need here. "
  },
};

import testimonials1 from "@/images/Testimonial Photo.jpeg";

export const TestimonialsOneData = [
  {
    image: testimonials1,
    content:
      "Five stars in every way!\n" +
        "\n" +
        "From accommodating our sometimes-crazy request for quick product shipment to competitive pricing and friendly service, I " +
        "couldn’t be more pleased with the service that we receive from Newby Enterprises. I would highly recommend to anyone.",
    name: "Loretta Johnson, Kootenay Signs "
  },
];

import aboutOne1 from "@/images/about-1-1.jpeg";
import aboutOne2 from "@/images/about-1-2.jpeg";
import aboutOne3 from "@/images/about-1-3.jpeg";

export const AboutOneData = [
  {
    title: "About Us",
    text:
      "Welcome to Newby Enterprises. We are a service-first, satisfaction-focused company that manufactures sign blanks" +
        " for print shops in the sign industry.",
    image: aboutOne1,
    url: "/about#us"
  },
  {
    title: "Our Promise",
    text:
      "Your satisfaction is our priority and our unparalleled service reflects that. Our customers enjoy " +
        "high-quality sign blanks, quick fulfillment turnaround, and exceptional customer care. ",
    image: aboutOne2,
    url: "/about#promise"
  },
  {
    title: "How We Work",
    text:
      "We’d love to work with you. If you would like to order any of our products, you can either submit a quote request" +
        " online or reach out to us for further assistance. ",
    image: aboutOne3,
    url: "/about#work"
  }
];

import serviceDetails1 from "@/images/service-details-1-1.jpg";
import serviceDetails2 from "@/images/service-details-1-2.jpg";
import serviceDetails3 from "@/images/service-details-1-3.jpg";

export const ServiceDetailsData = [
  {
    image: serviceDetails1,
    title: "Surprisingly Affordable",
    lists: [
      "16–17 pt paper thickness",
      "A premium paper for less",
      "Also in Business Cards and Letterhead"
    ]
  },
  {
    image: serviceDetails2,
    title: "Available in Matte or Gloss",
    lists: [
      "Matte is shine free, so no glare",
      "Gloss makes colors really “pop”",
      "Both come at no extra cost"
    ]
  },
  {
    image: serviceDetails3,
    title: "Versatile Finish Options",
    lists: [
      "Coat both sides for extra protection",
      "Leave one side uncoated to write on",
      'Or get that "traditional" Postcard feel'
    ]
  }
];

export const ServiceTabOneData = [
  {
    title: "Sign Blanks",
    icon: " printify-icon-hanging-billboard ",
    content: {
      title: "Sign Blanks",
      subText: "Our services",
      content: "We offer a variety of sign blanks for your signage needs. Many of our signs are cut daily for next-day " +
          "shipping and we even offer pre-printed signs to suit the more common sign demands. \n"
    }
  },
  {
    title: "Sign Stands",
    icon: " printify-icon-computer-monitor-back-view",
    content: {
      title: "Sign Stands",
      subText: "Our services",
      content:
          "From sign stands to sign hangers, we have a wide variety of options that offer a range in durability, " +
          "transportability, stylishness, and more. You will find what you need here. \n" +
          "\n" }
  },
  {
    title: "Signposts",
    icon: "printify-icon-road-billboard",
    content: {
      title: "Signposts",
      subText: "Our services",
      content:
          "Our high-quality signposts are incredibly durable, ranging in size, and built with your needs in mind. You" +
          " can’t go wrong with these high-quality options. \n" +
          "\n"}
  },
  {
    title: "MultiPanel UK",
    icon: "printify-icon-cube-template",
    content: {
      title: "MultPanel UK",
      subText: "Our services",
      content:
        "Newby Enterprises in the Canadian Prairie distributor of Multipanels Alupanel. From eye-catching signage" +
          " and innovative POS displays, to highly effective wayfinding applications, these aluminium composite panels" +
          " are the all-round sign and display solution. "
    }
  },
  {
    title: "Custom Quotes",
    icon: "printify-icon-tablet-with-pen",
    content: {
      title: "Custom Quotes",
      subText: "Our services",
      content:
          "Looking for something specific? Let’s chat. We can build you a custom quote based on your specs, quantity, timeline, and more. We’re here to support you and would love to talk about how we can meet your needs."
    }
  },
];

export const FeatureTwoData = {
  sectionContent: {
    title: "Printify Unique \n Features ",
    subText: " Next printing level ",
    content:
      "Take your business cards, Flyers and Stickers to the next level with exclusive and innovative features."
  },
  posts: [
    {
      name: "Business \n Cards",
      url: "/service-details",
      icon: "printify-icon-white-sheets"
    },
    {
      name: "Flyer \n Printing",
      url: "/service-details",
      icon: "printify-icon-brochure-folded"
    },
    {
      name: "Sticker \n Printing",
      url: "/service-details",
      icon: "printify-icon-circular-sticker"
    }
  ]
};

import feature1 from "@/images/what-we-do-3-1.jpg";
import feature2 from "@/images/what-we-do-3-1-1.jpg";
import feature3 from "@/images/what-we-do-3-1-2.jpg";
import feature4 from "@/images/what-we-do-3-1-3.jpg";

export const FeatureOneData = {
  sectionContent: {
    title: "What We Do",
    subText: " Our features",
    content:
      "There are many variations of passages of lorem Ipsum available, but \n the majority have suffered alteration in some form."
  },
  posts: [
    {
      image: feature1,
      title: "We Make Every Card Unique",
      content:
        "Print a different image on the back of every single card at no upcharge. Watch the video for inspiration on how to get creative with Printfinity.",
      url: "/about"
    },
    {
      image: feature2,
      title: "Free Full-Color Printing",
      content:
        "Print full color on both sides of your business cards – it’s always included in the price.",
      url: "/about"
    },
    {
      image: feature3,
      title: "Rounded Corners for All",
      content:
        "Print full color on both sides of your business cards – it’s always included in the price.",
      url: "/about"
    },
    {
      image: feature4,
      title: "Satisfaction Guaranteed",
      content:
        "Print full color on both sides of your business cards – it’s always included in the price.",
      url: "/about"
    }
  ]
};

import serviceImage1 from "../assets/img/lease12.jpg"
import serviceImage2 from "@/images/service-1-2.jpg";
import serviceImage3 from "@/images/service-1-3.jpg";


export const ServiceOneData = [
  {
    image: serviceImage1,
    title: "Sign Blanks",
    text:
      "We offer a variety of sign blanks for your signage needs. Many of our signs are cut daily for next-day shipping" +
        " and we even offer pre-printed 60x60cm stop signs and 75x75x75cm yield signs on 3M HI INT. ",
    url: "/signblanks"
  },
  {
    image: serviceImage2,
    title: "Sign Stands",
    text:
      "From sign stands to sign hangers, we have a wide variety of options that offer a range in durability," +
        " transportability, stylishness, and more. You will find what you need here. ",
    url: "/signposts"
  },
  {
    image: serviceImage3,
    title: "Signposts",
    text:
      "Our high-quality signposts are incredibly durable, ranging in size, and built with your needs in mind. " +
        "You can’t go wrong with these high-quality options. ",
    url: "/signstands"
  },
];

import projectImage1 from "@/images/project-1-1.jpg";
import projectImage2 from "@/images/project-1-2.jpg";
import projectImage3 from "@/images/project-1-3.jpg";
import projectImage4 from "@/images/project-1-4.jpg";
import projectImage5 from "@/images/project-1-5.jpg";
import projectImage6 from "@/images/project-1-6.jpg";
export const ProjectOneData = [
  {
    image: projectImage1,
    category: "printing",
    title: "Thick Paper Book",
    url: "/project-details"
  },
  {
    image: projectImage2,
    category: "printing",
    title: "Ninety Nine You",
    url: "/project-details"
  },
  {
    image: projectImage3,
    category: "printing",
    title: "Colorful Photo Print",
    url: "/project-details"
  },
  {
    image: projectImage4,
    category: "printing",
    title: "Square Paper Book",
    url: "/project-details"
  },
  {
    image: projectImage5,
    category: "printing",
    title: "Book Copy",
    url: "/project-details"
  },
  {
    image: projectImage6,
    category: "printing",
    title: "C Creative Mess",
    url: "/project-details"
  }
];

import errorImage from "@/images/404-text.png";
export const errorData = {
  title: "Oops! This page is not available",
  text: "Please go back to home and try to find out once again.",
  image: errorImage
};

import progressImage1 from "@/images/what-we-do-2-1.jpg";

export const ProgressData = {
  sectionImage: progressImage1,
  sectionContent: {
    title: "The Process",
    subText: "What we do",
    content:
      "When you work with Newby Enterprises, you can expect responsive and friendly service, speedy fulfillment, " +
        "and high-quality products. Many of our signs are cut daily for next-day shipping and we offer pre-printed " +
        " 3M Hi Int stop and yield signs. Whether you’re looking for sign blanks, sign stands, or sign posts," +
        " you will find what you need here. "
  },
  posts: [
    {
      title: "Flyer \n Printing",
      price: "8.99",
      percentCount: "90"
    },
    {
      title: "Sticker \n Printing",
      price: "9.99",
      percentCount: "70"
    }
  ]
};

export const PricingData = {
  sectionContent: {
    title: "Plans & Pricing",
    subText: "Choose best plan",
    content:
      "There are many variations of passages of lorem Ipsum available, but \n the majority have suffered alteration in some form."
  },
  posts: [
    {
      name: "Standard plan",
      price: "25.00",
      icon: "printify-icon-continuous-feed-paper",
      url: "/contact",
      lists: [
        {
          name: "Rounded & Colored"
        },
        {
          name: "Preimum Paper"
        },
        {
          name: "Extra Thick"
        }
      ]
    },
    {
      name: "Premium plan",
      price: "35.00",
      icon: "printify-icon-brochure-folded",
      url: "/contact",
      lists: [
        {
          name: "Rounded & Colored"
        },
        {
          name: "Preimum Paper"
        },
        {
          name: "Extra Thick"
        }
      ]
    },
    {
      name: "Ultimate plan",
      price: "45.00",
      icon: "printify-icon-circular-sticker",
      url: "/contact",
      lists: [
        {
          name: "Rounded & Colored"
        },
        {
          name: "Preimum Paper"
        },
        {
          name: "Extra Thick"
        }
      ]
    }
  ]
};

import clientImage1 from "@/images/1.png";
import clientImage2 from "@/images/2.png";
import clientImage3 from "@/images/3.png";


export const ClientCarouselData = [
  {
    image: clientImage1
  },
  {
    image: clientImage2
  },
  {
    image: clientImage3
  },
];

import sidebarImage from "@/images/widget-1-1.png";

export const sidebarTextWidgetData = {
  text:
    "Lorem Ipsum is simply dummy text of the rinting and typesetting industry has been the industry.",
  title: "Image with text",
  image: sidebarImage
};

import blogImage1 from "@/images/blog-1-1.jpg";
import blogImage2 from "@/images/blog-1-2.jpg";
import blogImage3 from "@/images/blog-1-3.jpg";
import blogImage4 from "@/images/blog-1-4.jpg";
import blogImage5 from "@/images/blog-1-5.jpg";
import blogImage6 from "@/images/blog-1-6.jpg";

export const BlogPostsData = [
  {
    title: "What final touches can i add",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage1,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "How to brand a tattoo studio",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage2,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "What file types do you accept",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage3,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "Do you offer design services",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage4,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "Bleed, trim and safe area",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage5,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "Can I use my own logo",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage6,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  }
];

import blogDetailsImage1 from "@/images/blog-details-1-1.jpg";

export const BlogDetailsData = {
  title: "What final touches can i add",
  text:
    "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
  image: blogDetailsImage1,
  author: "admin",
  date: "20 March, 2018",
  commentCount: "3 Comments",
  url: "/blog-details"
};

import commentImage1 from "@/images/comment-1-1.png";
import commentImage2 from "@/images/comment-1-2.png";

export const BlogCommentData = {
  sectionContent: {
    title: "2 Comments",
    subText: "Read comments"
  },
  posts: [
    {
      image: commentImage1,
      title: "Jayme Secord",
      date: "20 Mar, 2018",
      time: "4:00 pm",
      content:
        "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer nullam condimentum purus. In non ex at ligula fringilla lobortis et mauris auctor aliquet."
    },
    {
      image: commentImage2,
      title: "Lottie Golda",
      date: "20 Mar, 2018",
      time: "4:00 pm",
      content:
        "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer nullam condimentum purus. In non ex at ligula fringilla lobortis et mauris auctor aliquet."
    }
  ]
};

export const CommentFromData = {
  sectionContent: {
    title: "Write comment",
    subText: "Leave Comment"
  }
};

export const ContactFromData = {
  sectionContent: {
    title: "Get a Quote",
    subText: "Connect with us"
  }
};
export const ContactFromData2= {
  sectionContent: {
    title: "How can we help?",
    subText: "Connect with us"
  }
};

export const ContactInfosData = {
  sectionContent: {
    title: "Details",
    subText: "Contact info",
    textAlignment: "center"
  },
  posts: [
    {
      title: "Address",
      text: "4208 16a St SE,  \nT2G 3V1, \nCalgary, AB"
    },
    {
      title: "Phone",
      text: "Local: +14032717447 \n \n Toll Free: 18772714371"
    },
    {
      title: "Email",
      text: "newbyent@telus.net"
    },
      ]
};

export const FooterAboutData = {
  title: "Newby Enterprise",
  text:
    "Headquartered in Calgary, Alberta, we have been proudly serving our community and beyond since 1998. Whether you" +
      " reach out with clear specifications or you require our guidance to satisfy your industry’s standards, we will" +
      " ensure you leave with exactly what you need. "
};

export const FooterLinksData = {
  title: "Explore",
  links: [
    {
      label: "About",
      url: "/about"
    },
    {
      label: "Contact",
      url: "/contact"
    },
    {
      label: "Our Products",
      url: "/products"
    },
    {
      label: "Quote",
      url: "/quote"
    },
    {
      label: "How it Works",
      url: "/about"
    }
  ]
};

export const FooterPostsData = {
  title: "Latest Posts",
  posts: [
    {
      title: "A Clean Website Gives More Experience To The Visitors",
      date: "July 12, 2019",
      url: "/blog-details"
    },
    {
      title: "A Clean Website Gives More Experience To The Visitors",
      date: "July 12, 2019",
      url: "/blog-details"
    }
  ]
};

export const FooterContactData = {
  title: "Contact",
  infos: [
    {
      text: "(403) 271-7447",
      url: "tel:403-271-7447"
    },
    {
      text: "(877) 271-4371",
      url: "tel:877-271-4371"
    },
    {
      text: "newbyent@telus.net",
      url: "mailto:newbyent@telus.net"
    },
    {
      text: "4208 16a St SE,  \n Calgary, AB T2G 3V1",
      url: ""
    }
  ]
};

export const FooterBottomData = {
  social: [
    {
      icon: "fab fa-twitter",
      url: "#"
    },
    {
      icon: "fab fa-pinterest",
      url: "#"
    },
    {
      icon: "fab fa-facebook-f",
      url: "#"
    },
    {
      icon: "fab fa-youtube",
      url: "#"
    }
  ]
};
